/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 14-06-2021
 * Descriptions : Reducer for authentication
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
//Import constants variables
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
  GET_SNOOZE,
  PERMISSIONS,
  FORGOT_PASWD_SUCCESS,
  FORGOT_PASWD_ERROR,
  OTP_ERROR,
  OTP_SUCCESS,
  LOGIN_ATTEMPT_ERROR,
  LOGIN_USER_SUCCESS,
  CHECK_SYSTEM_IP,
  RELOAD_LOGIN,
  LOGIN_USER_FAILURE,
  WORD_PREVIEW_CLIENT,
  PRES_CLIENTID,
  ACTIVE_SESSION_TAG,
  OTP_LOGIN_SUCCESS,
  OTP_LOGIN_ERROR,
  OTP_PASSWORD_ERROR,
  LOGOUT_ID,
} from '../../shared/constants/ActionTypes';
//Initial state
const INIT_STATE = {
  user: null,
  token: null,
  snooze: {},
  userrolePermissions: [],
  forgotpaswdsuccess: '',
  wordPreviewClient:[],
  presClientId:0,
  forgotpaswderror: '',
  otpsuccess: false,
  otperror: '',
  otpPasswordError: '',
  loginattempterror: '',
  systemIp: false,
  reloadbrowser: false,
  loginerror: '',
  otploginsuccess: false,
  otploginerror: '',
  logout_id: false,
  active_session_tag: false,
};
//Declare class
const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //Update authenticate user
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    // LOGIN_USER_FAILURE
    case LOGIN_USER_FAILURE: {
      return {
        ...state,
        loginerror: action.payload,
      };
    }
    case WORD_PREVIEW_CLIENT: {
      return {
        ...state,
        wordPreviewClient: action.payload,
      };
    }
    case PRES_CLIENTID: {
      return {
        ...state,
        presClientId: action.payload,
      };
    }
    case ACTIVE_SESSION_TAG: {
      return {
        ...state,
        active_session_tag: action.payload,
      };
    }
    case LOGOUT_ID: {
      return {
        ...state,
        logout_id: action.payload,
      };
    }
    // system ip
    case CHECK_SYSTEM_IP: {
      return {
        ...state,
        systemIp: action.payload,
      };
    }
    // browser reload
    case RELOAD_LOGIN: {
      return {
        ...state,
        reloadbrowser: action.payload,
      };
    }
    //Logout success message
    case SIGNOUT_AUTH_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }
    //Set authentication token
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    //Get snooze time
    case GET_SNOOZE: {
      return {
        ...state,
        snooze: action.payload,
      };
    }
    //Get user permissions
    case PERMISSIONS: {
      return {
        ...state,
        userrolePermissions: action.payload,
      };
    }
    //Get success message for forgot paswd success
    case FORGOT_PASWD_SUCCESS:
      return {
        ...state,
        forgotpaswdsuccess: action.payload,
      };
    //Get error message for forgot paswd success
    case FORGOT_PASWD_ERROR:
      return {
        ...state,
        forgotpaswderror: action.payload,
      };
    //Get otp success
    case OTP_SUCCESS: {
      return {
        ...state,
        otpsuccess: action.payload,
      };
    }
    //Get otp error
    case OTP_ERROR: {
      return {
        ...state,
        otperror: action.payload,
      };
    }
    //Login attempt error
    case LOGIN_ATTEMPT_ERROR: {
      return {
        ...state,
        loginattempterror: action.payload,
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loginusersuccess: action.payload,
      };
    }
    //Get otp success
    case OTP_LOGIN_SUCCESS: {
      return {
        ...state,
        otploginsuccess: action.payload,
      };
    }
    //Get otp error
    case OTP_LOGIN_ERROR: {
      return {
        ...state,
        otploginerror: action.payload,
      };
    }
    case OTP_PASSWORD_ERROR: {
      return {
        ...state,
        otpPasswordError: action.payload,
      };
    }
    default:
      return state;
  }
};
export default authReducer;
