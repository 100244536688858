/************
 * Author : Suriya V
 * Developed by : Shiva Software Solutions
 * Date : 23-06-2021
 * Descriptions : Call api response using reducers
 * Modified Date :
 * Modified Author Name :
 * Modified Fuction :
 ************/
import {
  PENDING_HOSPITAL_DET,
  PENDING_CLIENT_HOSPITAL_DET,
  EDIT_HOSPITAL_DET,
  CLIENT_HOSPITAL_DET,
  GET_HC_DATA,
  BOT_QUESTIONS,
  REMINDER_QUESTIONS,
  PEND_BOT_QUESTIONS,
  DASHBOARD_PENDING_DATA,
  DASHBOARD_LIST_RESULT,
  DIGI_LIST_RESULT,
  TELE_LIST_RESULT,
  DASHBOARD_PENDING_COUNT,
  AMAZON_PENDING_DATA,
  DIGI_PENDING_DATA,
  DIGI_PENDING_COUNT,
  AMAZON_PENDING_COUNT,
  AMAZON_TIMEDIFF_COUNT,
  AMAZON_FAILURE_COUNT,
  TOTAL_AMAZON_PENDING_COUNT,
  TOTAL_DIGI_PENDING_COUNT,
  TOTAL_AMAZON_FAILURE_COUNT,
  TOTAL_AMAZON_REJECT_COUNT,
  TOTAL_DIGI_FAILURE_COUNT,
  TOTAL_DIGI_REJECT_COUNT,
  AMAZON_ERX_DOC_OBSV,
  AMAZON_RX_DETAILS,
  AMAZON_ERX_LAB_OBSV,
  AMAZON_ERX_PATIENT_VITAL,
  AMAZON_ERX_DOCPROCEDURE,
  AMAZON_ERX_MEDICINE_DETAIL,
  AMAZON_GRX_MEDICINE_DETAIL,
  GET_AMAZON_DOCTOR_EDITDATA,
  AMAZON_DOCTORS_SUGGESTIONS,
  AMAZON_SEARCH_CONSULTING_TIME,
  DASHBOARD_PENDING_PATIENT_DATA,
  DASHBOARD_IPDPENDING_PATIENT_DATA,
  DASHBOARD_DEPARTMENT,
  AMAZON_PENDING_PATIENT_DATA,
  AMAZON_PENDING_PATIENT_DETAILS,
  AMAZON_ERX_DETAILS,
  AMAZON_RX_LINK,
  PREVIEW_AMAZON_RX_LINK,
  AMAZON_CONSULT_TIMING,
  AMAZON_IDLE_TIME,
  AMAZON_CALL_LOG_COUNT,
  PATIENT_SUCCESS_MSG,
  AMAZON_PATIENT_SUCCESS_MSG,
  ERX_SUCCESS_MSG,
  DASHBOARD_ERX_DATA,
  ERX_IMG_ARR,
  GETHOSPITAL_ID,
  DASHBOARD_ERX_TESTNAME,
  ERX_IMG_OCR,
  GET_DOCTOR_EDITDATA,
  GET_DOCTOR_GANGADATA,
  GET_DOCTOR_SUGGESTION_HOSPITAL,
  GET_AMAZON_DOCTOR,
  DASHBOARD_INSERT_PRESCRIPTION,
  DASHBOARD_INPROGRESSS_DATA,
  DASHBOARD_INPROGRESSS_COUNT,
  AMAZON_INPROGRESSS_DATA,
  DIGI_INPROGRESSS_DATA,
  AMAZON_INPROGRESSS_COUNT,
  DIGI_INPROGRESSS_COUNT,
  DOCTOR_SUCCESS_MSG,
  REJECT_ERX_MSG,
  DOCTOR_UPDATE_MSG,
  MEDICINE_SUCCESS_MSG,
  GENERICMEDICINE_SUCCESS_MSG,
  DASHBOARD_RX_DETAILS,
  DASHBOARD_ERX_DOC_OBSV,
  DASHBOARD_ERX_LAB_OBSV,
  DASHBOARD_ERX_PATIENT_VITAL,
  DASHBOARD_ERX_DOCPROCEDURE,
  DASHBOARD_ERX_MEDICINE_DETAIL,
  DASHBOARD_GRX_MEDICINE_DETAIL,
  IPD_DISCHARGE_ADVICE,
  SHOWN_GEN_FIELD,
  DASHBOARD_REJECT_DATA,
  AMAZON_REJECT_DATA,
  DIGI_REJECT_COUNT,
  DIGI_REJECT_DATA,
  AMAZON_REJECT_COUNT,
  DASHBOARD_REJECT_COUNT,
  REJECT_ERX_SUCCESS_MSG,
  DASHBOARD_COMPLETED_COUNT,
  UNIT_DATA,
  ROUTE_DATA,
  MEDICINE_CATEGORY_DATA,
  MEDICINE_REJECT_REASONS,
  ERX_OPERATOR_NOTES,
  DASHBOARD_COMPLETED_DATA,
  AMAZON_COMPLETED_DATA,
  DIGI_COMPLETED_DATA,
  AMAZON_FAILURE_DATA,
  AMAZON_COMPLETED_COUNT,
  DIGI_FAILURE_DATA,
  DIGI_FAILURE_COUNT,
  DIGI_COMPLETED_COUNT,
  TOTAL_AMAZON_COMPLETED_COUNT,
  TOTAL_DIGI_COMPLETED_COUNT,
  TOTAL_AMAZON_INPROGRESS_COUNT,
  TOTAL_DIGI_INPROGRESS_COUNT,
  DELETE_MEDCINE_SUCCESS_MSG,
  DELETE_GENERICMEDCINE_SUCCESS_MSG,
  SET_DOCTOR_ID_NAME,
  DOCTOR_SPECIALIZATION,
  CALL_REASONS,
  DOCTOR_SUBSPECIALIZATION,
  DOCTOR_QUALIFICATION,
  DEPARTMENT_MASTER,
  STATE_MASTER,
  USER_PERMISSIONS,
  GET_CAMERA_PERMISSION,
  MEDIA_lIST,
  REJECT_REASONS,
  SETTINGS,
  pulseList,
  weightList,
  bpList,
  tempList,
  spo2List,
  hemoglobinList,
  serumList,
  bloodureaList,
  tlcount,
  bloodsugarList,
  resulttitle,
  OPT_NOTES,
  DOCTOR_SEARCH_ARRAY,
  DOCTOR_SEARCH_DATA,
  MERGE_SEARCH_DATA,
  HOSPITAL_SEARCH_DATA,
  HOSPITAL_SEARCH_OLD_DATA,
  ONLINE_OFFLINE_STATUS,
  GENERATE_PDF,
  GENERATE_AMAZON_PDF,
  GET_PATIENT,
  SELECTED_PRESCRIPTION_DATE,
  SEND_SUCCESS_MSG,
  SEND_AMAZON_SUCCESS_MSG,
  CALLBACK_SUCCESS_MSG,
  AMAZON_CALL_MSG,
  SEND_AMAZON_ALERT_MSG,
  SEARCH_CONSULTING_TIME,
  TOTAL_DASHBOARD_COMPLETED_COUNT,
  TOTAL_DASHBOARD_INPROGRESSS_COUNT,
  TOTAL_DASHBOARD_REJECT_COUNT,
  TOTAL_DASHBOARD_CALLCOMPLETE_COUNT,
  TOTAL_DASHBOARD_CALLBACk_COUNT,
  TOTAL_DASHBOARD_PENDING_COUNT,
  TAB_ERROR,
  TAB_DEPT_ERROR,
  TAB_ERROR_VITAL,
  DOCTOR_MAIL_ID,
  DOCTOR_VALIDATION,
  DOCTOR_VALIDATION_MOBILE,
  REFRESH_DASHBOARD,
  durationList,
  REFERRALLIST,
  DOSAGELIST,
  ADD_MEDICINE,
  CALL_ATTEMPTS,
  SUGGEST_MED,
  AMAZON_MED,
  SELECT_MED,
  SUGGEST_PAT,
  APPROVE_MEDICINE_MSG,
  CALLBACK_MSG,
  REFRESH_CURRENT_TAB,
  DASHBOARD_CALL_COMPLETED_DATA,
  DASHBOARD_CALL_COMPLETED_COUNT,
  DASHBOARD_CALL_PENDING_DATA,
  DASHBOARD_CALL_PENDING_COUNT,
  DASHBOARD_SUCCESS_MSG,
  EXIST_COUNT,
  INSERT_EXIST_COUNT,
  RESET_COUNT_DOWN,
  MATCHED_DOCTORS,
  DOCTOR_SELECT_DATA,
  MATCHED_APPROVED_DOCTORLIST,
  DOCTOR_CATEGORY,
  SET_DOCTOR_CATEGORY,
  APPROVED_DOCTOR_CATEGORY,
  CLIENT_MASTER_DATA,
  MATCHED_FETCH_SUCCESS,
  EDIT_DOCTOR,
  MATCHED_DOCTORS_SUCCESS,
  UHID_CHANGE,
  GET_MASTER_LIST_FOR_VITAL,
  APPROVE_STATUS_RESULT,
  DELETE_VITAL_STATUS_RESULT,
  ERX_HTML_DATA,
  ERX_HTML_NO_DATA,
  CAPTURE_IMAGE,
  MATCHED_APPROVED_DOCTORLIST_VIEW,
  EDIT_DOCTOR_VIEW,
  MATCHED_FETCH_SUCCESS_VIEW,
  INVESTIGATION_CATEGORY_DATA,
  REPORT_DETAILS,
  REPORT_VALIDATION,
  REPORT_ERROR,
  BUCKET_CAPTCHA,
  APPOINTMENT_REASON,
  APPOINTMENT_SUCCESS,
  APPOINTMENT_SUCCESS_REFERESH,
  SEARCH_PATIENT_LIST,
  SEARCH_PATIENT_LIST_COUNT,
  PATIENT_APPOINTMENT_LIST,
  PATIENT_APPOINTMENT_COUNT,
  TAG_CATEGORY_DATA,
  APPOINTMENT_PATIENTS,
  CLIENT_DETAILS,
  ERX_LIST_REPORT,
  ERX_LIST_REPORT_COUNT,
  OPERATOR_LIST,
  TAB_ERROR_INVESTIGATION,
  CLIENT_LIST,
  CLIENT_DASHBOARD_DETAILS,
  CLIENT_EXCEL_LIST,
  CLIENT_SEARCH_LIST,
  CLIENT_SEARCH_LIST_COUNT,
  OTP_TIME,
  CLIENT_BILLING_DETAILS,
  VENDOR_LIST,
  CLIENT_DASHBOARD_CLIST,
  CLIENT_ERX_LIST,
  CLIENT_ERX_LIST_COUNT,
  CLIENT_ERX_TOTAL_FEES,
  OCR_DETAIL,
  DOCTORS_SUGGESTIONS,
  SETTINGS_DATA,
  SUGGEST_MED_MESSAGE,
  SUGGEST_NER_MED_MESSAGE,
  SUGGEST_MED_NER,
  MEDICINE_SUGGESTIONS,
  DUPLICATE_ERX_DATA,
  SUGGEST_INVESTIGATION,
  SUGGEST_INVES_MSG,
  ENTITY_MAPPING,
  SEARCH_NER_INVESTIGATION,
  SEARCH_NER_SYMPTOMS,
  SEARCH_NER_PROCEDURE,
  SEARCH_NER_DISEASES,
  SEARCH_NER_DIAGNOSIS,
  SEARCH_NER_ALLERGY,
  SEARCH_NER_HOSPITALS,
  SUGGEST_MED_OPTION,
  SUGGEST_PAT_MESSAGE,
  DOCTOR_AUTOFILL,
  VITAL_SUGGESTIONS,
  TAB_ERROR_REVIEW,
  AUTOMARKCROP_COORDINATES,
  GET_APPROVED_VITALS,
  GET_CALL_REASONS,
  DIGI_SEARCH_LIST,
  TELE_SEARCH_LIST,
  DOCTOR_NOTES_TEST_NAMES,
  DIGI_SEARCH_TOKEN,
  TELE_SEARCH_TOKEN,
  SELECTED_OCR_DOCTOR_NOTES,
  SET_DOCTOR_DEPARTMENT_ID,
  GANGA_MEDSUGG,
  NERMOB_RESULT,
  GANGARAM_PATIENT_DATA,
  GANGARAM_SUGG_ID,
  AMAZON_PATIENT_NAME,
  DIGITIZATION_TIMEDIFF_COUNT,
  TAB_HOSPITAL_ERROR,
  SET_DOCTOR_HOSPITAL_ID,
  DOCTOR_NOTES_EDITOR_IMAGE,
  GET_CONSULTANT_DOCTOR_EDITDATA,
} from '../../shared/constants/ActionTypes';
//Initial state
const initialState = {
  healthCare: null,
  dashboardPendingList: {},
  pendingCount: 0,
  dashboardPendingPatientData: {},
  dashboardipdPendingPatientData: {},
  loadDepartmentList: [],
  patientsuccessmsg: null,
  erxsuccessmsg: null,
  rejecterxsuccessmsg: null,
  erxmedicinesuccessmsg: null,
  dashboardListResult: false,
  digiListResult: false,
  dashboarderxTestName: [],
  teleListResult: false,
  reset_count_down: '',
  dashboarderxData: {},
  erximgdatas: [],
  otp_time: '',
  amazon_reject_data: [],
  digi_reject_data: [],
  amazon_reject_count: 0,
  digi_reject_count: 0,
  erximgocrdatas: [],
  digi_failure_count: 0,
  digi_failure_data: [],
  get_amazon_doctor: [],
  call_reasons: [],
  call_attempts: [],
  client_search_list: [],
  client_search_list_count: 0,
  amazon_med: [],
  medicine_category_data: [],
  medicine_reject_reasons: [],
  amazon_pending_patient_data: {},
  amazon_patient_details: {},
  generate_amazon_pdf: [],
  clientHospitalDet: [],
  clientDashboardList: [],
  pendingClientHospitalDetails: [],
  send_amazon_success_msg: '',
  callback_success_msg: '',
  amazon_call_msg: '',
  send_amazon_alert_msg: '',
  amazon_erx_details: [],
  amazon_rx_link: [],
  preview_amazon_rx_link: '',
  amazon_consult_timing: '',
  amazon_idle_time: '',
  amazon_call_log_count: '',
  dashboarderxDetails: {},
  dashboarderxDocobsv: {},
  med_option: [],
  amazon_completed_data: [],
  digi_completed_data: [],
  amazon_failure_data: [],
  amazon_completed_count: 0,
  digi_completed_count: 0,
  amazon_pending_data: [],
  digitization_pending_data: [],
  amazon_pending_count: 0,
  amazon_timediff_count: [],
  digitization_pending_count: 0,
  amazon_failure_count: 0,
  total_amazon_pending_count: '',
  total_amazon_failure_count: '',
  total_digi_failure_count: '',
  total_amazon_reject_count: '',
  total_digi_reject_count: '',
  total_amazon_completed_count: '',
  total_digi_completed_count: '',
  total_digi_pending_count: '',
  total_amazon_inprogress_count: '',
  total_digi_inprogress_count: '',
  total_dashboard_completed_count: '',
  total_dashboard_inprogresss_count: '',
  total_dashboard_reject_count: '',
  total_dashboard_callcomplete_count: '',
  total_dashboard_callback_count: '',
  total_dashboard_pending_count: '',
  amazon_erx_doc_obsv: [],
  amazon_rx_details: [],
  amazon_erx_lab_obsv: [],
  amazon_erx_patient_vital: [],
  amazon_erx_docprocedure: [],
  amazon_patient_success_msg: [],
  amazon_erx_medicine_detail: [],
  amazon_grx_medicine_detail: [],
  get_amazon_doctor_editdata: [],
  amazon_doctors_suggestions: [],
  amazon_search_consulting_time: [],
  amazon_inprogresss_data: [],
  digi_inprogresss_data: [],
  amazon_inprogresss_count: 0,
  digi_inprogresss_count: 0,
  dashboarderxLabobsv: {},
  dashboarderxPatientVital: {},
  dashboarderxDocProcedure: [],
  dashboarderxmedicinedetail: {},
  ipdDischargeAdvice: '',
  shownGenDtls: false,
  dashboardgrxmedicinedetail: {},
  doctorDetails: {},
  gangaDrDet: '',
  suggestmedicine: {},
  suggestmedicineMsg: '',
  gangaMedSugg: '',
  nerMobileResult: [],
  suggestmedicineNer: {},
  medicine_suggestions: '',
  suggestmedicineMsgNer: '',
  dashboardinsertprescriptions: {},
  inprogresCount: 0,
  selectmed: [],
  suggestpat: [],
  suggestionpatmessage: '',
  dashboardInprogressList: {},
  dashboardRejectList: {},
  dashboardRejectcountList: null,
  doctorsuccessmsg: null,
  doctorupdatemsg: null,
  rejectsuccessmsg: null,
  dashboardCompletedList: {},
  dashboardCompletedcountList: null,
  dashboardcallcompletedCount: null,
  dashboardcallcompletedList: {},
  dashboardcallpendingCount: null,
  transferSuccessmsg: '',
  dashboardcallpendingList: {},
  unitdata: [],
  routeData: [],
  erxOperatorNotes: [],
  deletemedicinesuccessmsg: null,
  doctoridname: {},
  specializations: [],
  subspecializations: [],
  qualifications: [],
  departmentMaster: [],
  stateMaster: [],
  userPermissions: {},
  cameraPermissions: false,
  settings: [],
  optNotes: '',
  doctorSearchDatas: [],
  searchableDoctor: {},
  searchableHospital: {},
  onlineOfflineStatus: {},
  generatepdf: {},
  getpatient: {},
  prescriptionDate: '',
  sendsuccessmsg: '',
  searchConsultingTime: [],
  mediaList: [],
  rejectReasons: [],
  errortabtextmsg: '',
  errorDeptMsg: '',
  errorHosMsg: '',
  errorvitaltabtextmsg: '',
  doctormailid_validation: '',
  validationmsg: '',
  refreshDashboard: '',
  doctorMobileError: '',
  resulttitle: {},
  bloodsugarList: {},
  tlcount: {},
  bloodureaList: {},
  pulseList: {},
  weightList: [],
  duarionList: [],
  bpList: {},
  tempList: {},
  spo2List: {},
  hemoglobinList: {},
  serumList: {},
  addmedcineprocess: '',
  approvemedicinemsg: '',
  callbackmsg: '',
  dosageList: [],
  referralList: [],
  counterx_exist: '',
  insertcounterx_exist: '',
  matchedDoctorList: [],
  selectedDoctor: '',
  matchedapprovedDoctorList: [],
  editDoctor: '',
  matchedSuccess: '',
  matcheddoctorresult: '',
  uhidChange: '',
  vitalmasterdetails: [],
  approvestatusresult: '',
  deletevitalstatusresult: '',
  erxhtmldata: {},
  erxhtmlnodata: '',
  doctorcategory: {},
  setdoctorcategory: {},
  approveddoctorcategory: {},
  clientMasterData: {},
  captureImage: '',
  matchedapprovedDoctorListView: [],
  editDoctorView: '',
  edithospitaldetails: '',
  pendinghospitaldetails: '',
  matchedSuccessView: '',
  investigationCategoryData: [],
  reportDetails: [],
  reportvalidationmsg: '',
  reporterrormsg: '',
  hospitalValidationId: {},
  bucketCaptcha: {},
  appointmentReason: [],
  appointmentSuccess: '',
  appointmentSuccessRefersh: false,
  searchPatientList: [],
  searchPatientListCount: 0,
  patientAppointmentCount: 0,
  patientAppointments: [],
  tagCategoryData: [],
  appointmentPatients: [],
  clientDetails: {},
  erxListReport: {},
  erxListReportCount: 0,
  operatorList: [],
  errorInvestigation: '',
  appointmentData: '',
  clientList: [],
  clientDashboardDetails: {},
  clientExcelDetails: {},
  clientBillingDetails: {},
  clientErxList: {},
  clientErxListCount: 0,
  ocrvalue: '',
  doctorSuggestions: '',
  settingData: [],
  clientTotalErxFees: 0,
  duplicateErxData: [],
  botquestions: [],
  pendbotquestions: [],
  reminderquestions: [],
  doctor_suggestion_hospital: [],
  doctorAutofill: '',
  errorvitalreviewmsg: '',
  callreasons: [],
  dotorNotes_test_names: [],
  digitization_search_token: '',
  selected_ocr_doctor_notes: '',
  doctordepartmentId: 0,
  doctorInfoHospitalID: 0,
  gangaPatSugg: [],
  gangaramSuggId: 0,
  amazonPatientName: '',
  digitization_timediff_count: [],
  doctorNotes_EditorImage: '',
  consultantdoctorDetails: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HC_DATA:
      return {
        ...state,
        healthCare: action.payload,
      };
    case DASHBOARD_ERX_TESTNAME:
      return {
        ...state,
        dashboarderxTestName: action.payload,
      };
    //Get ocr data
    case OCR_DETAIL:
      return {
        ...state,
        ocrvalue: action.payload,
      };
    case BOT_QUESTIONS:
      return {
        ...state,
        botquestions: action.payload,
      };
    case REMINDER_QUESTIONS:
      return {
        ...state,
        reminderquestions: action.payload,
      };
    case GENERATE_AMAZON_PDF:
      return {
        ...state,
        generate_amazon_pdf: action.payload,
      };

    case AMAZON_PENDING_PATIENT_DATA:
      return {
        ...state,
        amazon_pending_patient_data: action.payload,
      };

    case AMAZON_PENDING_PATIENT_DETAILS:
      return {
        ...state,
        amazon_patient_details: action.payload,
      };
    case AMAZON_ERX_DETAILS:
      return {
        ...state,
        amazon_erx_details: action.payload,
      };
    case AMAZON_RX_LINK:
      return {
        ...state,
        amazon_rx_link: action.payload,
      };
    case PREVIEW_AMAZON_RX_LINK:
      return {
        ...state,
        preview_amazon_rx_link: action.payload,
      };
    case AMAZON_CONSULT_TIMING:
      return {
        ...state,
        amazon_consult_timing: action.payload,
      };
    case AMAZON_IDLE_TIME:
      return {
        ...state,
        amazon_idle_time: action.payload,
      };
    case AMAZON_CALL_LOG_COUNT:
      return {
        ...state,
        amazon_call_log_count: action.payload,
      };
    case SUGGEST_PAT_MESSAGE:
      return {
        ...state,
        suggestionpatmessage: action.payload,
      };
    case PEND_BOT_QUESTIONS:
      return {
        ...state,
        pendbotquestions: action.payload,
      };
    case AMAZON_MED:
      return {
        ...state,
        amazon_med: action.payload,
      };
    //Get operator notes
    case OPT_NOTES:
      return {
        ...state,
        optNotes: action.payload,
      };
    //GET_MASTER_LIST_FOR_VITAL
    case GET_MASTER_LIST_FOR_VITAL:
      return {
        ...state,
        vitalmasterdetails: action.payload,
      };
    //APPROVE_STATUS_RESULT
    case APPROVE_STATUS_RESULT:
      return {
        ...state,
        approvestatusresult: action.payload,
      };
    //DELETE_VITAL_STATUS_RESULT
    case DELETE_VITAL_STATUS_RESULT:
      return {
        ...state,
        deletevitalstatusresult: action.payload,
      };
    case SUGGEST_PAT:
      return {
        ...state,
        suggestpat: action.payload,
      };

    //Assign dashboard pending data
    case DASHBOARD_PENDING_DATA:
      return {
        ...state,
        dashboardPendingList: action.payload,
      };
    case DASHBOARD_LIST_RESULT:
      return {
        ...state,
        dashboardListResult: action.payload,
      };
    case DIGI_LIST_RESULT:
      return {
        ...state,
        digiListResult: action.payload,
      };
    case TELE_LIST_RESULT:
      return {
        ...state,
        teleListResult: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_PENDING_COUNT:
      return {
        ...state,
        pendingCount: action.payload,
      };
    //Assign dashboard pending data
    case DASHBOARD_REJECT_DATA:
      return {
        ...state,
        dashboardRejectList: action.payload,
      };
    case AMAZON_REJECT_DATA:
      return {
        ...state,
        amazon_reject_data: action.payload,
      };
    case DIGI_REJECT_DATA:
      return {
        ...state,
        digi_reject_data: action.payload,
      };
    case AMAZON_REJECT_COUNT:
      return {
        ...state,
        amazon_reject_count: action.payload,
      };
    case DIGI_REJECT_COUNT:
      return {
        ...state,
        digi_reject_count: action.payload,
      };
    //Assign dashboard pending count
    case DASHBOARD_REJECT_COUNT:
      return {
        ...state,
        dashboardRejectcountList: action.payload,
      };
    //Assign dashboard pending Patient data
    case DASHBOARD_PENDING_PATIENT_DATA:
      return {
        ...state,
        dashboardPendingPatientData: action.payload,
      };
    case DASHBOARD_IPDPENDING_PATIENT_DATA:
      return {
        ...state,
        dashboardipdPendingPatientData: action.payload,
      };
    case DASHBOARD_DEPARTMENT:
      return {
        ...state,
        loadDepartmentList: action.payload,
      };
    //Assign dashboard pending Patient success msg
    case PATIENT_SUCCESS_MSG:
      return {
        ...state,
        patientsuccessmsg: action.payload,
      };
    case AMAZON_PATIENT_SUCCESS_MSG:
      return {
        ...state,
        amazon_patient_success_msg: action.payload,
      };
    //Assign successmsg
    case ERX_SUCCESS_MSG:
      return {
        ...state,
        erxsuccessmsg: action.payload,
      };
    //Assign successmsg
    case REJECT_ERX_MSG:
      return {
        ...state,
        rejecterxsuccessmsg: action.payload,
      };

    //Assign successmsg
    case MEDICINE_SUCCESS_MSG:
      return {
        ...state,
        erxmedicinesuccessmsg: action.payload,
      };
    //Assign successmsg FOR GENERIC
    case GENERICMEDICINE_SUCCESS_MSG:
      return {
        ...state,
        erxmedicinesuccessmsg: action.payload,
      };
    //Assign dashboard pending erx data
    case DASHBOARD_ERX_DATA:
      return {
        ...state,
        dashboarderxData: action.payload,
      };

    //get image data
    case ERX_IMG_ARR:
      return {
        ...state,
        erximgdatas: action.payload,
      };
    //get erx with img data
    case ERX_IMG_OCR:
      return {
        ...state,
        erximgocrdatas: action.payload,
      };
    //Get dashboard erx details
    case DASHBOARD_RX_DETAILS:
      return {
        ...state,
        dashboarderxDetails: action.payload,
      };
    //Get erx doctor observation details
    case DASHBOARD_ERX_DOC_OBSV:
      return {
        ...state,
        dashboarderxDocobsv: action.payload,
      };
    //Get erx lab observation details
    case DASHBOARD_ERX_LAB_OBSV:
      return {
        ...state,
        dashboarderxLabobsv: action.payload,
      };
    //Get dashboard erx patient vital
    case DASHBOARD_ERX_PATIENT_VITAL:
      return {
        ...state,
        dashboarderxPatientVital: action.payload,
      };
    //Get dashboard erx procedure
    case DASHBOARD_ERX_DOCPROCEDURE:
      return {
        ...state,
        dashboarderxDocProcedure: action.payload,
      };
    //Get erx medicine details
    case DASHBOARD_ERX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboarderxmedicinedetail: action.payload,
      };
    case IPD_DISCHARGE_ADVICE:
      return {
        ...state,
        ipdDischargeAdvice: action.payload,
      };
    case SHOWN_GEN_FIELD:
      return {
        ...state,
        shownGenDtls: action.payload,
      };
    //Get grx medicine details
    case DASHBOARD_GRX_MEDICINE_DETAIL:
      return {
        ...state,
        dashboardgrxmedicinedetail: action.payload,
      };
    //bind doctor edit load data
    case GET_DOCTOR_EDITDATA:
      return {
        ...state,
        doctorDetails: action.payload,
      };
    case GET_DOCTOR_GANGADATA:
      return {
        ...state,
        gangaDrDet: action.payload,
      };
    case GET_DOCTOR_SUGGESTION_HOSPITAL:
      return {
        ...state,
        doctor_suggestion_hospital: action.payload,
      };
    //bind doctor edit load data
    case GET_AMAZON_DOCTOR:
      return {
        ...state,
        get_amazon_doctor: action.payload,
      };
    //Insert prescription
    case DASHBOARD_INSERT_PRESCRIPTION:
      return {
        ...state,
        dashboardinsertprescriptions: action.payload,
      };
    //Assign dashboard inprogress data
    case DASHBOARD_INPROGRESSS_DATA:
      return {
        ...state,
        dashboardInprogressList: action.payload,
      };
    //Assign inprogress count
    case DASHBOARD_INPROGRESSS_COUNT:
      return {
        ...state,
        inprogresCount: action.payload,
      };
    case TOTAL_DASHBOARD_COMPLETED_COUNT:
      return {
        ...state,
        total_dashboard_completed_count: action.payload,
      };
    case TOTAL_DASHBOARD_INPROGRESSS_COUNT:
      return {
        ...state,
        total_dashboard_inprogresss_count: action.payload,
      };
    case TOTAL_DASHBOARD_REJECT_COUNT:
      return {
        ...state,
        total_dashboard_reject_count: action.payload,
      };
    case TOTAL_DASHBOARD_CALLCOMPLETE_COUNT:
      return {
        ...state,
        total_dashboard_callcomplete_count: action.payload,
      };
    case TOTAL_DASHBOARD_CALLBACk_COUNT:
      return {
        ...state,
        total_dashboard_callback_count: action.payload,
      };
    case TOTAL_DASHBOARD_PENDING_COUNT:
      return {
        ...state,
        total_dashboard_pending_count: action.payload,
      };
    case AMAZON_INPROGRESSS_DATA:
      return {
        ...state,
        amazon_inprogresss_data: action.payload,
      };
    case DIGI_INPROGRESSS_DATA:
      return {
        ...state,
        digi_inprogresss_data: action.payload,
      };
    case DIGI_INPROGRESSS_COUNT:
      return {
        ...state,
        digi_inprogresss_count: action.payload,
      };
    case AMAZON_INPROGRESSS_COUNT:
      return {
        ...state,
        amazon_inprogresss_count: action.payload,
      };
    //doctor success msg
    case DOCTOR_SUCCESS_MSG:
      return {
        ...state,
        doctorsuccessmsg: action.payload,
      };

    //doctor update msg
    case DOCTOR_UPDATE_MSG:
      return {
        ...state,
        doctorupdatemsg: action.payload,
      };

    //REJECT_ERX_SUCCESS_MSG
    case REJECT_ERX_SUCCESS_MSG:
      return {
        ...state,
        rejectsuccessmsg: action.payload,
      };
    //Get completed data
    case DASHBOARD_COMPLETED_DATA:
      return {
        ...state,
        dashboardCompletedList: action.payload,
      };
    case AMAZON_COMPLETED_COUNT:
      return {
        ...state,
        amazon_completed_count: action.payload,
      };
    case DIGI_COMPLETED_COUNT:
      return {
        ...state,
        digi_completed_count: action.payload,
      };
    case AMAZON_COMPLETED_DATA:
      return {
        ...state,
        amazon_completed_data: action.payload,
      };
    case DIGI_COMPLETED_DATA:
      return {
        ...state,
        digi_completed_data: action.payload,
      };
    case DIGI_FAILURE_DATA:
      return {
        ...state,
        digi_failure_data: action.payload,
      };
    case DIGI_FAILURE_COUNT:
      return {
        ...state,
        digi_failure_count: action.payload,
      };
    case AMAZON_FAILURE_DATA:
      return {
        ...state,
        amazon_failure_data: action.payload,
      };
    case TOTAL_AMAZON_COMPLETED_COUNT:
      return {
        ...state,
        total_amazon_completed_count: action.payload,
      };
    case TOTAL_DIGI_COMPLETED_COUNT:
      return {
        ...state,
        total_digi_completed_count: action.payload,
      };
    case TOTAL_DIGI_PENDING_COUNT:
      return {
        ...state,
        total_digi_pending_count: action.payload,
      };
    case TOTAL_AMAZON_INPROGRESS_COUNT:
      return {
        ...state,
        total_amazon_inprogress_count: action.payload,
      };
    case TOTAL_DIGI_INPROGRESS_COUNT:
      return {
        ...state,
        total_digi_inprogress_count: action.payload,
      };
    //Get completed count data
    case DASHBOARD_COMPLETED_COUNT:
      return {
        ...state,
        dashboardCompletedcountList: action.payload,
      };

    //Get call completed data
    case DASHBOARD_CALL_COMPLETED_DATA:
      return {
        ...state,
        dashboardcallcompletedList: action.payload,
      };
    //Get call completed count data
    case DASHBOARD_CALL_COMPLETED_COUNT:
      return {
        ...state,
        dashboardcallcompletedCount: action.payload,
      };

    //Get call completed data
    case DASHBOARD_CALL_PENDING_DATA:
      return {
        ...state,
        dashboardcallpendingList: action.payload,
      };
    //Get call completed count data
    case DASHBOARD_CALL_PENDING_COUNT:
      return {
        ...state,
        dashboardcallpendingCount: action.payload,
      };
    // show Success message
    case DASHBOARD_SUCCESS_MSG:
      return {
        ...state,
        transferSuccessmsg: action.payload,
      };
    //Get unit data master
    case UNIT_DATA:
      return {
        ...state,
        unitdata: action.payload,
      };
    //Get route data
    case ROUTE_DATA:
      return {
        ...state,
        routeData: action.payload,
      };

    case MEDICINE_CATEGORY_DATA:
      return {
        ...state,
        medicine_category_data: action.payload,
      };

    case MEDICINE_REJECT_REASONS:
      return {
        ...state,
        medicine_reject_reasons: action.payload,
      };
    //Get operator notes data
    case ERX_OPERATOR_NOTES:
      return {
        ...state,
        erxOperatorNotes: action.payload,
      };
    //Assign delete medicine details
    case DELETE_MEDCINE_SUCCESS_MSG:
      return {
        ...state,
        deletemedicinesuccessmsg: action.payload,
      };
    //Assign delete genericmedicine details
    case DELETE_GENERICMEDCINE_SUCCESS_MSG:
      return {
        ...state,
        deletegenericmedicinesuccessmsg: action.payload,
      };
    //get doctor id name
    case SET_DOCTOR_ID_NAME:
      return {
        ...state,
        doctoridname: action.payload,
      };

    //for specialization master array
    case DOCTOR_SPECIALIZATION:
      return {
        ...state,
        specializations: action.payload,
      };
    case CALL_REASONS:
      return {
        ...state,
        call_reasons: action.payload,
      };
    //Get doctor specialization
    case DOCTOR_SUBSPECIALIZATION:
      return {
        ...state,
        subspecializations: action.payload,
      };
    //Doctor qualification
    case DOCTOR_QUALIFICATION:
      return {
        ...state,
        qualifications: action.payload,
      };

    // master list
    case DEPARTMENT_MASTER:
      return {
        ...state,
        departmentMaster: action.payload,
      };
    //Get state master
    case STATE_MASTER:
      return {
        ...state,
        stateMaster: action.payload,
      };
    //Get user permissions
    case USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    //Get camera permissions
    case GET_CAMERA_PERMISSION:
      return {
        ...state,
        cameraPermissions: action.payload,
      };
    //Get MEDIAlIST
    case MEDIA_lIST:
      return {
        ...state,
        mediaList: action.payload,
      };

    //Get REJECT_REASONS
    case REJECT_REASONS:
      return {
        ...state,
        rejectReasons: action.payload,
      };

    //Get pulse list
    case pulseList:
      return {
        ...state,
        pulseList: action.payload,
      };

    //Get pulse list
    case weightList:
      return {
        ...state,
        weightList: action.payload,
      };

    //Get BP list data
    case bpList:
      return {
        ...state,
        bpList: action.payload,
      };
    //Get temporary list
    case tempList:
      return {
        ...state,
        tempList: action.payload,
      };
    //Get spo2 list
    case spo2List:
      return {
        ...state,
        spo2List: action.payload,
      };
    //Get hemoglobin List
    case hemoglobinList:
      return {
        ...state,
        hemoglobinList: action.payload,
      };
    //Get serum List
    case serumList:
      return {
        ...state,
        serumList: action.payload,
      };
    //Get blood urea list data
    case bloodureaList:
      return {
        ...state,
        bloodureaList: action.payload,
      };
    //Get tl count
    case tlcount:
      return {
        ...state,
        tlcount: action.payload,
      };
    //Get blood sugar list
    case bloodsugarList:
      return {
        ...state,
        bloodsugarList: action.payload,
      };
    //Get title list
    case resulttitle:
      return {
        ...state,
        resulttitle: action.payload,
      };

    //Get durationList
    case durationList:
      return {
        ...state,
        durationList: action.payload,
      };
    //Get referralList
    case REFERRALLIST:
      return {
        ...state,
        referralList: action.payload,
      };
    //Get dosage list
    case DOSAGELIST:
      return {
        ...state,
        dosageList: action.payload,
      };
    //Get settings list
    case SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    //Get doctor search array
    case DOCTOR_SEARCH_ARRAY:
      return {
        ...state,
        doctorSearchDatas: action.payload,
      };
    //Doctor search data
    case DOCTOR_SEARCH_DATA:
      return {
        ...state,
        searchableDoctor: action.payload,
      };
    case MERGE_SEARCH_DATA:
      return {
        ...state,
        mergeableDoctor: action.payload,
      };
    //Get hosipital data
    case HOSPITAL_SEARCH_DATA:
      return {
        ...state,
        searchableHospital: action.payload,
      };
    //Get hosipital data
    case HOSPITAL_SEARCH_OLD_DATA:
      return {
        ...state,
        searchableOldHospital: action.payload,
      };
    //Get search consulting time
    case SEARCH_CONSULTING_TIME:
      return {
        ...state,
        searchConsultingTime: action.payload,
      };
    //Get online offline status
    case ONLINE_OFFLINE_STATUS:
      return {
        ...state,
        onlineOfflineStatus: action.payload,
      };
    //Get generate pdf
    case GENERATE_PDF:
      return {
        ...state,
        generatepdf: action.payload,
      };
    //Get patient data
    case GET_PATIENT:
      return {
        ...state,
        getpatient: action.payload,
      };
    //Get selected prescription date
    case SELECTED_PRESCRIPTION_DATE:
      return {
        ...state,
        prescriptionDate: action.payload,
      };
    //Get success message
    case SEND_SUCCESS_MSG:
      return {
        ...state,
        sendsuccessmsg: action.payload,
      };
    case SEND_AMAZON_SUCCESS_MSG:
      return {
        ...state,
        send_amazon_success_msg: action.payload,
      };
    case CALLBACK_SUCCESS_MSG:
      return {
        ...state,
        callback_success_msg: action.payload,
      };
    case AMAZON_CALL_MSG:
      return {
        ...state,
        amazon_call_msg: action.payload,
      };
    case SEND_AMAZON_ALERT_MSG:
      return {
        ...state,
        send_amazon_alert_msg: action.payload,
      };
    //Get tab message
    case TAB_ERROR:
      return {
        ...state,
        errortabtextmsg: action.payload,
      };
    //Get tab message
    case TAB_DEPT_ERROR:
      return {
        ...state,
        errorDeptMsg: action.payload,
      };
    case TAB_HOSPITAL_ERROR:
      return {
        ...state,
        errorHosMsg: action.payload,
      };
    //Get vital tab message
    case TAB_ERROR_VITAL:
      return {
        ...state,
        errorvitaltabtextmsg: action.payload,
      };
    case DOCTOR_MAIL_ID:
      return {
        ...state,
        doctormailid_validation: action.payload,
      };
    //Doctor validation
    case DOCTOR_VALIDATION:
      return {
        ...state,
        validationmsg: action.payload,
      };

    case DOCTOR_VALIDATION_MOBILE:
      return {
        ...state,
        doctorMobileError: action.payload,
      };
    //Doctor validation
    case GETHOSPITAL_ID:
      return {
        ...state,
        hospitalValidationId: action.payload,
      };
    //Refresh validation
    case REFRESH_DASHBOARD:
      return {
        ...state,
        refreshDashboard: action.payload,
      };

    //ADD MEDICINE SUCCESS MSG
    case ADD_MEDICINE:
      return {
        ...state,
        addmedcineprocess: action.payload,
      };
    case CALL_ATTEMPTS:
      return {
        ...state,
        call_attempts: action.payload,
      };
    case SUGGEST_MED:
      return {
        ...state,
        suggestmedicine: action.payload,
      };
    case SELECT_MED:
      return {
        ...state,
        selectmed: action.payload,
      };
    case SUGGEST_MED_MESSAGE:
      return {
        ...state,
        suggestmedicineMsg: action.payload,
      };
    case SUGGEST_MED_NER:
      return {
        ...state,
        suggestmedicineNer: action.payload,
      };

    case MEDICINE_SUGGESTIONS:
      return {
        ...state,
        medicine_suggestions: action.payload,
      };
    case SUGGEST_NER_MED_MESSAGE:
      return {
        ...state,
        suggestmedicineMsgNer: action.payload,
      };
    case SUGGEST_MED_OPTION:
      return {
        ...state,
        med_option: action.payload,
      };
    case DUPLICATE_ERX_DATA:
      return {
        ...state,
        duplicateErxData: action.payload,
      };
    //APPROVE MEDICINE  SUCCESS MSG
    case APPROVE_MEDICINE_MSG:
      return {
        ...state,
        approvemedicinemsg: action.payload,
      };

    //CALLBACK SUCCESS MSG
    case CALLBACK_MSG:
      return {
        ...state,
        callbackmsg: action.payload,
      };

    //REFRESH_CURRENT_TAB MSG
    case REFRESH_CURRENT_TAB:
      return {
        ...state,
        refreshTab: action.payload,
      };

    case EXIST_COUNT:
      return {
        ...state,
        counterx_exist: action.payload,
      };
    case INSERT_EXIST_COUNT:
      return {
        ...state,
        insertcounterx_exist: action.payload,
      };
    case RESET_COUNT_DOWN:
      return {
        ...state,
        reset_count_down: action.payload,
      };

    //get doctorList
    case MATCHED_DOCTORS:
      return {
        ...state,
        matchedDoctorList: action.payload,
      };

    case DOCTOR_SELECT_DATA:
      return {
        ...state,
        selectedDoctor: action.payload,
      };
    case SET_DOCTOR_CATEGORY:
      return {
        ...state,
        setdoctorcategory: action.payload,
      };
    case DOCTOR_CATEGORY:
      return {
        ...state,
        doctorcategory: action.payload,
      };
    case APPROVED_DOCTOR_CATEGORY:
      return {
        ...state,
        approveddoctorcategory: action.payload,
      };
    case CLIENT_MASTER_DATA:
      return {
        ...state,
        clientMasterData: action.payload,
      };
    case MATCHED_APPROVED_DOCTORLIST:
      return {
        ...state,
        matchedapprovedDoctorList: action.payload,
      };
    case MATCHED_FETCH_SUCCESS:
      return {
        ...state,
        matchedSuccess: action.payload,
      };
    case EDIT_DOCTOR:
      return {
        ...state,
        editDoctor: action.payload,
      };
    case MATCHED_APPROVED_DOCTORLIST_VIEW:
      return {
        ...state,
        matchedapprovedDoctorListView: action.payload,
      };
    case MATCHED_FETCH_SUCCESS_VIEW:
      return {
        ...state,
        matchedSuccessView: action.payload,
      };
    case EDIT_DOCTOR_VIEW:
      return {
        ...state,
        editDoctorView: action.payload,
      };
    case EDIT_HOSPITAL_DET:
      return {
        ...state,
        edithospitaldetails: action.payload,
      };
    case CLIENT_HOSPITAL_DET:
      return {
        ...state,
        clientHospitalDet: action.payload,
      };
    case PENDING_HOSPITAL_DET:
      return {
        ...state,
        pendinghospitaldetails: action.payload,
      };
    case PENDING_CLIENT_HOSPITAL_DET:
      return {
        ...state,
        pendingClientHospitalDetails: action.payload,
      };
    case MATCHED_DOCTORS_SUCCESS:
      return {
        ...state,
        matcheddoctorresult: action.payload,
      };
    case UHID_CHANGE:
      return {
        ...state,
        uhidChange: action.payload,
      };
    case ERX_HTML_NO_DATA:
      return {
        ...state,
        erxhtmlnodata: action.payload,
      };
    case ERX_HTML_DATA:
      return {
        ...state,
        erxhtmldata: action.payload,
      };

    case CAPTURE_IMAGE:
      return {
        ...state,
        healthCare: action.payload,
      };
    //Get Investigation category data
    case INVESTIGATION_CATEGORY_DATA:
      return {
        ...state,
        investigationCategoryData: action.payload,
      };
    case REPORT_DETAILS:
      return {
        ...state,
        reportDetails: action.payload,
      };
    //Doctor validation
    case REPORT_VALIDATION:
      return {
        ...state,
        reportvalidationmsg: action.payload,
      };
    case REPORT_ERROR:
      return {
        ...state,
        reporterrormsg: action.payload,
      };
    case BUCKET_CAPTCHA:
      return {
        ...state,
        bucketCaptcha: action.payload,
      };
    case APPOINTMENT_REASON:
      return {
        ...state,
        appointmentReason: action.payload,
      };
    case APPOINTMENT_SUCCESS:
      return {
        ...state,
        appointmentSuccess: action.payload,
      };
    case APPOINTMENT_SUCCESS_REFERESH:
      return {
        ...state,
        appointmentSuccessRefersh: action.payload,
      };

    //Assign dashboard pending data
    case SEARCH_PATIENT_LIST:
      return {
        ...state,
        searchPatientList: action.payload,
      };
    //Assign dashboard pending count
    case SEARCH_PATIENT_LIST_COUNT:
      return {
        ...state,
        searchPatientListCount: action.payload,
      };
    case PATIENT_APPOINTMENT_LIST:
      return {
        ...state,
        patientAppointments: action.payload,
      };
    case PATIENT_APPOINTMENT_COUNT:
      return {
        ...state,
        patientAppointmentCount: action.payload,
      };
    case TAG_CATEGORY_DATA:
      return {
        ...state,
        tagCategoryData: action.payload,
      };
    case APPOINTMENT_PATIENTS:
      return {
        ...state,
        appointmentPatients: action.payload,
      };
    case SETTINGS_DATA:
      return {
        ...state,
        settingData: action.payload,
      };

    case CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: action.payload,
      };

    //Get ERXREPORT data
    case ERX_LIST_REPORT:
      return {
        ...state,
        erxListReport: action.payload,
      };

    //Get completed count data
    case ERX_LIST_REPORT_COUNT:
      return {
        ...state,
        erxListReportCount: action.payload,
      };

    case OPERATOR_LIST:
      return {
        ...state,
        operatorList: action.payload,
      };

    //Get tab message
    case TAB_ERROR_INVESTIGATION:
      return {
        ...state,
        errorInvestigation: action.payload,
      };

    case CLIENT_LIST:
      return {
        ...state,
        clientList: action.payload,
      };
    case CLIENT_DASHBOARD_DETAILS:
      return {
        ...state,
        clientDashboardDetails: action.payload,
      };

    case CLIENT_EXCEL_LIST:
      return {
        ...state,
        clientExcelDetails: action.payload,
      };
    case CLIENT_SEARCH_LIST:
      return {
        ...state,
        client_search_list: action.payload,
      };
    case CLIENT_SEARCH_LIST_COUNT:
      return {
        ...state,
        client_search_list_count: action.payload,
      };
    case OTP_TIME:
      return {
        ...state,
        otp_time: action.payload,
      };
    case CLIENT_BILLING_DETAILS:
      return {
        ...state,
        clientBillingDetails: action.payload,
      };

    //Get VENDOR_LIST
    case VENDOR_LIST:
      return {
        ...state,
        vendorList: action.payload,
      };

    case CLIENT_DASHBOARD_CLIST:
      return {
        ...state,
        clientDashboardList: action.payload,
      };

    //Get ERXREPORT data
    case CLIENT_ERX_LIST:
      return {
        ...state,
        clientErxList: action.payload,
      };
    case CLIENT_ERX_TOTAL_FEES:
      return {
        ...state,
        clientTotalErxFees: action.payload,
      };

    //Get completed count data
    case CLIENT_ERX_LIST_COUNT:
      return {
        ...state,
        clientErxListCount: action.payload,
      };

    //bind doctor edit load data
    case DOCTORS_SUGGESTIONS:
      return {
        ...state,
        doctorSuggestions: action.payload,
      };
    case DOCTOR_AUTOFILL:
      return {
        ...state,
        doctorAutofill: action.payload,
      };
    case SUGGEST_INVESTIGATION:
      return {
        ...state,
        suggestInvestigation: action.payload,
      };
    case AMAZON_PENDING_DATA:
      return {
        ...state,
        amazon_pending_data: action.payload,
      };
    case DIGI_PENDING_DATA:
      return {
        ...state,
        digitization_pending_data: action.payload,
      };
    case DIGI_PENDING_COUNT:
      return {
        ...state,
        digitization_pending_count: action.payload,
      };

    case AMAZON_PENDING_COUNT:
      return {
        ...state,
        amazon_pending_count: action.payload,
      };
    case AMAZON_FAILURE_COUNT:
      return {
        ...state,
        amazon_failure_count: action.payload,
      };
    case TOTAL_AMAZON_PENDING_COUNT:
      return {
        ...state,
        total_amazon_pending_count: action.payload,
      };
    case AMAZON_TIMEDIFF_COUNT:
      return {
        ...state,
        amazon_timediff_count: action.payload,
      };
    case TOTAL_AMAZON_FAILURE_COUNT:
      return {
        ...state,
        total_amazon_failure_count: action.payload,
      };
    case TOTAL_DIGI_FAILURE_COUNT:
      return {
        ...state,
        total_digi_failure_count: action.payload,
      };
    case TOTAL_AMAZON_REJECT_COUNT:
      return {
        ...state,
        total_amazon_reject_count: action.payload,
      };
    case TOTAL_DIGI_REJECT_COUNT:
      return {
        ...state,
        total_digi_reject_count: action.payload,
      };
    case AMAZON_ERX_DOC_OBSV:
      return {
        ...state,
        amazon_erx_doc_obsv: action.payload,
      };
    case AMAZON_RX_DETAILS:
      return {
        ...state,
        amazon_rx_details: action.payload,
      };
    case AMAZON_ERX_LAB_OBSV:
      return {
        ...state,
        amazon_erx_lab_obsv: action.payload,
      };
    case AMAZON_ERX_PATIENT_VITAL:
      return {
        ...state,
        amazon_erx_patient_vital: action.payload,
      };
    case AMAZON_ERX_DOCPROCEDURE:
      return {
        ...state,
        amazon_erx_docprocedure: action.payload,
      };
    case AMAZON_ERX_MEDICINE_DETAIL:
      return {
        ...state,
        amazon_erx_medicine_detail: action.payload,
      };
    case AMAZON_GRX_MEDICINE_DETAIL:
      return {
        ...state,
        amazon_grx_medicine_detail: action.payload,
      };
    case GET_AMAZON_DOCTOR_EDITDATA:
      return {
        ...state,
        get_amazon_doctor_editdata: action.payload,
      };
    case AMAZON_DOCTORS_SUGGESTIONS:
      return {
        ...state,
        amazon_doctors_suggestions: action.payload,
      };
    case AMAZON_SEARCH_CONSULTING_TIME:
      return {
        ...state,
        amazon_search_consulting_time: action.payload,
      };
    case SUGGEST_INVES_MSG:
      return {
        ...state,
        suggestinvestigationMsg: action.payload,
      };
    case ENTITY_MAPPING:
      return {
        ...state,
        entityMapping: action.payload,
      };
    case SEARCH_NER_INVESTIGATION:
      return {
        ...state,
        searchNerinvestigation: action.payload,
      };

    case SEARCH_NER_SYMPTOMS:
      return {
        ...state,
        searchNersymptoms: action.payload,
      };

    case SEARCH_NER_PROCEDURE:
      return {
        ...state,
        searchNerprocedure: action.payload,
      };
    case 'SEARCH_NER_DISEASES':
      return {
        ...state,
        searchNerdiseases: action.payload,
      };

    case 'SEARCH_NER_DIAGNOSIS':
      return {
        ...state,
        searchNerdiagnosis: action.payload,
      };
    case 'SEARCH_NER_ALLERGY':
      return {
        ...state,
        searchNerallergy: action.payload,
      };
    case 'SEARCH_NER_HOSPITALS':
      return {
        ...state,
        searchNerHospitals: action.payload,
      };
    case VITAL_SUGGESTIONS:
      return {
        ...state,
        vitallen: action.payload,
      };
    case TAB_ERROR_REVIEW:
      return {
        ...state,
        errorvitalreviewmsg: action.payload,
      };

    case AUTOMARKCROP_COORDINATES:
      return {
        ...state,
        cropcoordinates: action.payload,
      };
    case GET_CALL_REASONS:
      return {
        ...state,
        callreasons: action.payload,
      };
    case DIGI_SEARCH_LIST:
      return {
        ...state,
        digitization_search_list: action.payload,
      };
    case TELE_SEARCH_LIST:
      return {
        ...state,
        teleconsultation_search_list: action.payload,
      };
    case DOCTOR_NOTES_TEST_NAMES:
      return {
        ...state,
        dotorNotes_test_names: action.payload,
      };
    case DIGI_SEARCH_TOKEN:
      return {
        ...state,
        digitization_search_token: action.payload,
      };
    case TELE_SEARCH_TOKEN:
      return {
        ...state,
        teleconsultation_search_token: action.payload,
      };

    case GET_APPROVED_VITALS:
      return {
        ...state,
        approved_vitals: action.payload,
      };
    case SELECTED_OCR_DOCTOR_NOTES:
      return {
        ...state,
        selected_ocr_doctor_notes: action.payload,
      };

    case SET_DOCTOR_DEPARTMENT_ID:
      return {
        ...state,
        doctordepartmentId: action.payload,
      };
    case SET_DOCTOR_HOSPITAL_ID:
      return {
        ...state,
        doctorInfoHospitalID: action.payload,
      };
    case GANGA_MEDSUGG:
      return {
        ...state,
        gangaMedSugg: action.payload,
      };
    case NERMOB_RESULT:
      return {
        ...state,
        nerMobileResult: action.payload,
      };
    case GANGARAM_PATIENT_DATA:
      return {
        ...state,
        gangaPatSugg: action.payload,
      };
    case GANGARAM_SUGG_ID:
      return {
        ...state,
        gangaramSuggId: action.payload,
      };
    case AMAZON_PATIENT_NAME:
      return {
        ...state,
        amazonPatientName: action.payload,
      };
    case DIGITIZATION_TIMEDIFF_COUNT:
      return {
        ...state,
        digitization_timediff_count: action.payload,
      };
    case DOCTOR_NOTES_EDITOR_IMAGE:
      return {
        ...state,
        doctorNotes_EditorImage: action.payload,
      };
    case GET_CONSULTANT_DOCTOR_EDITDATA:
      return {
        ...state,
        consultantdoctorDetails: action.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
