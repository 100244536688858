/************
 * Developed by : Shiva Software Solutions
 * Date : 15-06-2021
 * Descriptions : Route navigation for inner modules
 * Dependicies : To use @material-ui/core
 ************/

import {authRole} from '../shared/constants/AppConst';

const digitizationRouteConfig = [
  {
    id: 'dashbaord',
    title: 'Dashboard',
    messageId: 'sidebar.dashboard',
    type: 'single',
    icon: 'dashboard',
    auth: authRole.dashboardRoles,
    url: '/dashboard',
  },
  {
    id: 'amazondashboard',
    title: 'TeleConsultation Dashboard',
    messageId: 'sidebar.AdminAmazondashboard',
    type: 'single',
    icon: 'headphones',
    auth: authRole.amazonAdminRoles,
    url: '/amazondashboard',
  },
  {
    id: 'digitizationdashboard',
    title: 'Digitization Dashboard',
    messageId: 'sidebar.AdminDigitizationdashboard',
    type: 'single',
    icon: 'memory',
    auth: authRole.dashboardRoles,
    url: '/digitizationdashboard',
  },
  {
    id: 'searchPatient',
    title: 'Search Patient',
    messageId: 'sidebar.searchPatient',
    type: 'single',
    icon: 'manage_search',
    auth: authRole.dashboardRoles,
    url: '/searchPatient',
  },

  {
    id: 'controlPanel',
    title: 'Control Panel',
    messageId: 'sidebar.controlePanel',
    type: 'group',
    auth: authRole.adminRoles,
    icon: 'settings',

    children: [
      {
        id: 'masters',
        title: 'Masters',
        messageId: 'sidebar.masters',
        type: 'collapse',
        auth: authRole.adminRoles,
        // icon: 'assignmentInd',
        children: [
          {
            id: 'verifieddoctors',
            title: 'Doctors',
            messageId: 'sidebar.controlePanel.doctor',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/doctors',
          },
          {
            id: 'verifieddoctors',
            title: 'Doctors',
            messageId: 'sidebar.controlePanel.hospital',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/hospital-master',
          },
          {
            id: 'departmentmasters',
            title: 'Departments',
            messageId: 'sidebar.controlePanel.departments',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/departments',
          },
          {
            id: 'cameraMasters',
            title: 'cameraMasters',
            messageId: 'sidebar.masters.camera',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/cameramaster',
          },
          {
            id: 'languageMasters',
            title: 'languageMasters',
            messageId: 'sidebar.masters.languageMaster',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/languageMaster',
          },
          {
            id: 'symptoms',
            title: 'Symptoms',
            messageId: 'sidebar.masters.symptoms',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/symptoms',
          },
          {
            id: 'diagnosis',
            title: 'Diagnosis',
            messageId: 'sidebar.masters.diagnosis',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/diagnosis',
          },
          {
            id: 'procedure',
            title: 'Procedure',
            messageId: 'sidebar.masters.procedure',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/procedure',
          },
          {
            id: 'investigation',
            title: 'Investigation',
            messageId: 'sidebar.masters.investigation',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/investigation',
          },
          {
            id: 'allergy',
            title: 'Allergy',
            messageId: 'sidebar.masters.allergy',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/allergy',
          },
          {
            id: 'diseases',
            title: 'Diseases',
            messageId: 'sidebar.masters.diseases',
            type: 'item',
            auth: authRole.adminRoles,
            url: '/diseases',
          },
        ],
      },
      {
        id: 'clientmasters',
        title: 'ClientMasters',
        messageId: 'sidebar.masters.clientmaster',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/clients',
      },
      {
        id: 'adminApproval',
        title: 'Admin Approval',
        messageId: 'sidebar.masters.adminApproval',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/adminApproval',
      },
      {
        id: 'gudmedUsers',
        title: 'GudMed Users',
        messageId: 'sidebar.controlePanel.gudmedUser',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/gudmedusers',
      },
      {
        id: 'patientUsers',
        title: 'Patients',
        messageId: 'sidebar.controlePanel.patientUser',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/registered-patients',
      },
      {
        id: 'prescriptionpatientUsers',
        title: 'Patients',
        messageId: 'sidebar.controlePanel.prescriptionpatientUser',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/prescription-patients',
      },
      {
        id: 'doctorUsers',
        title: 'Doctors',
        messageId: 'sidebar.controlePanel.doctorUser',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/registered-doctors',
      },
      // {
      //   id: 'prescriptiondoctorUsers',
      //   title: 'Prescription Doctors',
      //   messageId: 'sidebar.controlePanel.prescriptiondoctorUser',
      //   type: 'item',
      //   auth: authRole.adminRoles,
      //   url: '/prescription-doctors',
      // },
      {
        id: 'ocrtraining',
        title: 'OCR Training',
        messageId: 'sidebar.controlePanel.training',
        type: 'item',
        auth: authRole.operatorRoles,
        url: '/training',
        checkauth: true,
      },
      // {
      //   id: 'clientcredentials',
      //   title: 'Client Credentials',
      //   messageId: 'sidebar.controlePanel.vendorcredential',
      //   type: 'item',
      //   auth: authRole.adminRoles,
      //   url: '/client-credentials',
      // },
      {
        id: 'mappingpatients',
        title: 'mappingpatients',
        messageId: 'sidebar.merge.mappingpatients',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/mergingPatient',
      },
      {
        id: 'Settings',
        title: 'Settings',
        messageId: 'sidebar.controlePanel.Settings',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/Settings',
      },
    ],
  },
  {
    id: 'controlPanel',
    title: 'Control Panel',
    messageId: 'sidebar.controlePanel',
    type: 'group',
    auth: authRole.operatorRoles,
    icon: 'settings',

    children: [
      {
        id: 'ocrtraining',
        title: 'OCR Training',
        messageId: 'sidebar.controlePanel.training',
        type: 'item',
        auth: authRole.operatorRoles,
        url: '/training',
        checkauth: true,
      },
    ],
  },

  {
    id: 'reports',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'group',
    auth: authRole.adminRoles,
    icon: 'receipt_long',
    children: [
      {
        id: 'abhaNumber',
        title: 'ABHA Number',
        messageId: 'sidebar.abhaNumber',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/abhaUsers',
      },
      {
        id: 'appointmentBookings',
        title: 'Appointment Bookings',
        messageId: 'sidebar.appointmentBookings',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/appointmentBooking',
      },
      {
        id: 'erxList',
        title: 'Digitized Prescriptions',
        messageId: 'sidebar.erxList',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/erxList',
      },
      {
        id: 'clientDashboard',
        title: 'Client Dashboard',
        messageId: 'sidebar.clientDashboard',
        type: 'item',
        url: '/clientDashboard',
        checkauth: true,
      },
      {
        id: 'vitals',
        title: 'Vitals',
        messageId: 'sidebar.vitals',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/covidVitals',
      },
      {
        id: 'reports',
        title: 'Reports',
        messageId: 'sidebar.reportpage',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/reports',
      },
      {
        id: 'amazonreports',
        title: 'Amazon eRx Reports',
        messageId: 'sidebar.amazonreportpage',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/amazonReports',
      },
      {
        id: 'digitizationreports',
        title: 'Digitization eRx Reports',
        messageId: 'sidebar.digitizationreportpage',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/digitizationreports',
      },
      {
        id: 'nerReport',
        title: 'NER Report',
        messageId: 'sidebar.nerentityStatus',
        type: 'item',
        url: '/nerReportentityruler',
      },
      {
        id: 'operatorReport',
        title: 'Operator Report',
        messageId: 'sidebar.operatorReport',
        type: 'item',
        auth: authRole.adminRoles,
        url: '/operatorReport',
      },
      // {
      //   id: 'opdDigitization',
      //   title: 'Operator Report',
      //   messageId: 'sidebar.opdDigitization',
      //   type: 'item',
      //   auth: authRole.adminRoles,
      //   url: '/opdDigitization',
      // },
    ],
  },
  {
    id: 'reportsoperator',
    title: 'Reports',
    messageId: 'sidebar.reports',
    type: 'group',
    auth: authRole.operatorRoles,
    icon: 'receipt_long',
    children: [
      {
        id: 'appointmentBookingsoperator',
        title: 'Appointment Bookings',
        messageId: 'sidebar.appointmentBookings',
        type: 'item',
        auth: authRole.operatorRoles,
        url: '/appointmentBooking',
      },
      {
        id: 'clientDashboard',
        title: 'Client Dashboard',
        messageId: 'sidebar.clientDashboard',
        type: 'item',
        url: '/clientDashboard',
        checkauth: true,
      },
    ],
  },
  // {
  //   id: 'ocrtraining',
  //   title: 'OCR Training',
  //   messageId: 'sidebar.controlePanel.training',
  //   type: 'single',
  //   auth: authRole.operatorRoles,
  //   url: '/training',
  //   checkauth: true,
  //   icon: 'assignmentInd',
  // },
];
export default digitizationRouteConfig;
